<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.system.siteSetting.types.' + siteSettingKey) }}
        </h2>

        <router-link to="/system/site-setting" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.system.siteSetting.title") }}
        </router-link>
    </div>
    <el-tabs v-model="tabModel" class="setting-tabs" @tab-click="changedTab">
        <el-tab-pane v-for="(setting, settingKey, settingIndex) in siteSettings" :key="settingIndex" :label="$t('pages.system.siteSetting.types.' + settingKey)" :name="settingKey">
            <div class="card" v-if="tabSkeleton">
                <div class="card-body d-flex flex-column p-9">
                    <el-skeleton />
                </div>
            </div>
            <el-form :model="form.data" ref="siteSettingForm" v-else-if="tabModel == settingKey">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="row fv-row mb-7 align-items-center" v-for="(item, itemKey, itemIndex) in setting.items" :key="itemIndex">
                            <div class="col-md-3 mb-2 mb-md-0">
                                <label class="fs-6 fw-bold" :class="{'required': item.options.required}">{{ item.title }}</label>
                            </div>
                            <div class="col-md-9">
                                <el-form-item :prop="itemKey" :rules="item.options.required && $validation.getMessage(['required'])" class="mb-0" v-init="form.data[itemKey] = item.value[$root.defaultLanguage.id]">
                                    <div class="d-flex">
                                        <div class="w-100"> <!-- customWidth with Button -->
                                            <FormElement :siteSettingItem="{item: item, itemKey: itemKey}" v-model="form.data[itemKey]" @updateUploadFiles="uploadFiles = $event"></FormElement>
                                        </div>

                                        <!--<button type="button" class="btn btn-sm btn-primary align-self-center ms-2" v-on:click="handleSiteSettingItem(item, itemKey)">
                                            <span class="svg-icon svg-icon-1 me-0">
                                                <inline-svg src="/media/icons/duotune/maps/map004.svg" />
                                            </span>
                                        </button>-->
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0 text-end">
                                <button @click.prevent="form.typeModal = false; onSubmit()" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
    </el-tabs>
    <div class="modal fade" id="kt_modal_item" ref="itemModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.modal" ref="itemForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('common.language') }}</label>
                                <el-form-item prop="language_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.modal.language_id" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedLanguage">
                                        <el-option v-for="(language, languageIndex) in $root.languages" :key="languageIndex" :value="language.id" :label="language.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2" :class="{'required': siteSettingItem.item.options.required}">{{ siteSettingItem.item.title }}</label>
                                <el-form-item :prop="'data.' + siteSettingItem.itemKey" :rules="siteSettingItem.item.options.required && $validation.getMessage(['required'])" class="mb-0">
                                    <FormElement :siteSettingItem="siteSettingItem" v-model="form.modal.data[siteSettingItem.itemKey]" @updateUploadFiles="uploadFiles = $event"></FormElement>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import FormElement from "@/components/system/setting/form-element";

export default {
    name: "index",
    components: {
        FormElement
    },
    data(){
        return {
            tabSkeleton: true,
            tabModel: undefined,
            form: {
                title: '',
                loading: false,
                typeModal: false,
                data: {},
                modal: {
                    data: {}
                }
            },
            siteSettingItem: {
                item: {
                    options: {}
                },
                itemKey: ''
            },
            siteSettings: {},
            uploadFiles: {}
        }
    },
    computed: {
        siteSettingKey() {
            return this.$route.params.id;
        }
    },
    created(){
        if (!this.siteSettingKey || !(this.siteSettingKey.length > 0)) {
            this.$router.push({ path: "/system/site-setting" });
        }

        this.tabModel = this.siteSettingKey;
    },
    mounted() {
        if (this.siteSettingKey && this.siteSettingKey.length > 0) {
            this.loadSiteSettings();
        }
    },
    methods: {
        loadSiteSettings() {
            this.tabSkeleton = true;

            this.axios.get(this.endpoints['site_setting']).then((response) => {
                let data = response.data.data;

                if (!data[this.siteSettingKey]) {
                    this.$router.push({path: "/system/site-setting"});
                }

                this.siteSettings = data;
            }).finally(() => {
                this.tabSkeleton = false;
            });
        },
        onSubmit() {
            let form = this.$refs[this.form.typeModal ? 'itemForm' : 'siteSettingForm'];

            form.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    let formData = {
                        language_id: this.$root.defaultLanguage.id,
                        data: this.form.data
                    };

                    if(this.form.typeModal){
                        formData = this.form.modal;
                    }

                    formData = this.cloneData(formData);

                    if (Object.keys(this.uploadFiles).length > 0) {
                        this.submitFormWithUpload(formData);
                    } else {
                        this.submitForm(formData);
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload(formData) {
            const fileFormData = new FormData();

            for (let index in this.uploadFiles) {
                fileFormData.append("file[]", this.uploadFiles[index][0].raw);
            }

            this.axios.post(this.endpoints["file"], fileFormData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;

                    Object.entries(this.uploadFiles).forEach(([key, value], index) => {
                        formData.data[key] = data[index].real_path;
                    });

                    this.uploadFiles = {};

                    this.submitForm(formData);
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm(formData){
            this.form.loading = true;

            this.axios.post(this.endpoints['site_setting'], formData).then(response => {
                this.onResponse(response.data, () => {
                    if(this.form.typeModal) {
                        this.hideModal(this.$refs.itemModal);
                    }

                    this.loadSiteSettings();
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        },
        changedTab(){
            this.$router.push({ path: "/system/site-setting/" + this.tabModel });
            this.tabSkeleton = true;

            this.form.data = {};
            this.form.modal = {
                data: {}
            };
            this.uploadFiles = {};

            setTimeout(() => {
                this.tabSkeleton = false;
            }, 0)
        },
        handleSiteSettingItem(item, itemKey){
            this.form.title = this.$t("common.translation");
            this.form.typeModal = true;

            this.siteSettingItem = {
                item: item,
                itemKey: itemKey
            };

            let data = {};
            data[itemKey] = item.value[this.$root.defaultLanguage.id];

            this.form.modal = {
                language_id: this.$root.defaultLanguage.id,
                data: data
            };

            this.showModal(this.$refs.itemModal);
        },
        selectedLanguage(){
            this.form.modal.data[this.siteSettingItem.itemKey] = this.siteSettingItem.item.value[this.form.modal.language_id];
        },
    }
}
</script>

<style>
.site-setting-upload .el-upload-list {
    width: fit-content;
}
.customWidth {
    width: calc(100% - 65.25px) !important;
}
</style>